import axios from 'axios';
import apiConfig from '@/configs/api'



const state = {
    staff: [],
    teams: [],
    isTeamActive: {}
}

const getters = {
    staff: state => state.staff,
    teams: state => state.teams,
    getTeamSkinnerByStylistId: state => stylistId => {
        const team = state.teams.find(({ staff }) => {
            return staff.find(s => s.id == stylistId);
        })
        const skinners = team ? team.staff.filter(s => s.departmentId == 2) : [];
        return skinners.length ? skinners[0].id : null;
    },
    isTeamActive: state => state.isTeamActive
}

const actions = {
    async setTeams({ commit }, { salonId, date, currentWorkHour }) {
        const { data: staff } = await axios.get(`${apiConfig.MAIN_API}/api/get-staff/stylist-skinner-team?salonId=${salonId}&workDate=${date.format("YYYY-MM-DD")}`);
        commit('setTeams', { salonId, staff, currentWorkHour, date })
    }
}

const mutations = {
    setTeams(state, { salonId, staff, currentWorkHour, date }) {
        staff = staff.map(s => ({
            ...s,
            workHours: s.hourIds.split(',').map(i => parseInt(i))
        }))
        state.staff = staff;
        
        const largestTeam = Math.max(...staff.map(s => s.teamId));
        let teams = new Array(largestTeam + 1).fill(null);
        
        for (let i = 0; i <= largestTeam; i++) {
            const isActive = false;
            const teamStaff = staff.filter(s => s.teamId == i);
            teamStaff.forEach(s => {
                if (s.workHours && s.workHours.includes(currentWorkHour.id)) isActive = true;
            })

            let team = {
                teamId: i,
                staff: teamStaff,
                teamKey: `${salonId}-${date.format("YYYY-MM-DD")}-${i}`,
                isActive
            }
            //if (!isActive) console.log('inactive', team)
            teams[i] = team;
            state.isTeamActive[i] = isActive;
        }
        teams = teams.filter(t => t.staff.length);


        state.teams = teams;
        //state.teams = state.teams.slice(0, 3);
        console.log('teams', state.teams);
    },
    updateIsTeamActive(state, { teamId, isActive }) {
        state.isTeamActive = {
            ...state.isTeamActive,
            [teamId]: isActive
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
