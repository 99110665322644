<template>
  <div class="has-background-white timeline is-mobile">
    <ModalContainer v-if="finishFirstRender"> </ModalContainer>
    <div class="head margin-1">
      <div class="level padding-10 is-mobile">
        <FilterBar />
        <!--<CategoryList v-if="finishFirstRender"/>-->
      </div>
    </div>
    <DifferentDayWarning class="margin-1" v-if="finishFirstRender && !isTodaySelected"/>
    
    <div class="columns is-gapless timeline-data is-mobile">
       <TeamColumn style="margin-top: 50px;"/>
       <FixHourHeader v-bind:hour="hourFix" />
       <div class="scroll" style="overflow-x: auto; width: 100%" v-on:scroll="onScroll">
        <div class="data-table" v-bind:style="{width:hours.length*270 + 10  +'px'}">
          <HourRow />
          <BillsTable />
        </div>
      </div>
    </div>

    
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
import axios from "axios";

import convertUtil from "@/utils/convert";

import TeamColumn from '@/components/Timeline/TeamColumn';
import BillsTable from '@/components/Timeline/BillsTable';
import FixHourHeader from '@/components/Timeline/FixHourHeader';

import {
  FilterBar,
  HourRow
} from "@/components/Timeline";

const CategoryList = () => import('@/components/Timeline/CategoryList');

import apiConfig from "@/configs/api";
import websocketUtil from "@/utils/websocket";

import notiUtil from "@/utils/noti";
import timeUtil from '@/utils/time';
import positionUtil from '@/utils/position';

export default {
  components: {
    CategoryList,
    FilterBar,
    HourRow,
    TeamColumn,
    BillsTable,
    FixHourHeader
  },
  name: "Home",

  data() {
    return {
      finishFirstRender: false,
      isLoading: false,
      draggedBooking: null,
      detectedCustomerPhones: [],
      isHourRowHovered: false,
      recognition: {
        isProducerConnected: null,
        isConsumerConnected: null,
        producerConnection: null,
        consumerConnection: null,
        isStreaming: null,
        detectedCustomers: {}
      },
      socket: null
    };
  },
  computed: {
    ...mapGetters([
      "hours",
      "user",
      "dateNormal",
      "timelineFilter",
      "teams",
      "bills",
      'services',
      'getTeamSkinnerByStylistId',
      'workHours',
      'isTodaySelected'
    ]),
    selectedSalon() {
      return this.timelineFilter.salon;
    },
    hourFix(){
        const _hour = this.hours.length> 0 ? this.hours[0].hour.split('h')[0] : '';
        return _hour;
    }
  },

  methods: {
    ...mapActions(["setHours", "setServices", "setProducts", 'setBills', 'setTeams', 'setWorkHours']),
    ...mapMutations(['clearConnectingLines', 'setBillItemsMap', 'updateStageData', 'updateBill', 'resetBills']),
    onScroll(e){
       document.getElementsByClassName('team-column-container')[0].scrollTop = e.target.scrollTop;
       var fixedHour =  document.getElementsByClassName('hour-container')[0];
       fixedHour.style.position = 'absolute';
       fixedHour.style.top = e.target.scrollTop + 'px';
       if(e.target.scrollLeft !== 0){  
           document.getElementsByClassName('fix-hour')[0].style.display = 'none';
       }else{
          document.getElementsByClassName('fix-hour')[0].style.display = 'block';
       }
    },
    async fetchData(){
      this.clearConnectingLines();
      await this.setHours(this.selectedSalon.id);

      await this.setWorkHours(this.selectedSalon.id);
      const currentWorkHour = timeUtil.getCurrentWorkHour(this.workHours);
      await this.setTeams({ salonId: this.selectedSalon.id, date: moment(this.timelineFilter.date), currentWorkHour })
      
      await this.setBills({ salonId: this.selectedSalon.id, date: moment(this.timelineFilter.date)});

      await this.setServices();
      

      this.processBills();
    },

    fillIsCallTimeForBookings() {

      const filledBills = this.bills.map(b => {
        if (b.isCallTime) return b;

        let hourItem;
        if (b.secondSubHourId) {
          hourItem = this.hours.find(h => h.secondSubHourId == b.secondSubHourId);
        } else {
          hourItem = this.hours.find(h => h.subHourId == b.subHourId)
        }
        const isCallTime = `${moment().format('YYYY-MM-DD')}T${hourItem.hourFrame}`;
        
        const skinnerId = this.getTeamSkinnerByStylistId(b.staffHairdresserId); 
        if (skinnerId) this.updateBill({ bookingId: b.bookingId, field: 'staffHairMassageId', value: skinnerId } )

        return {
          ...b,
          staffHairMassageId: skinnerId,
          isCallTime,
          isCallTimePredicted: true
        }
      })

      this.resetBills(filledBills);
    },

    processBills() {
      if (this.isTodaySelected) this.fillIsCallTimeForBookings();

      const stages = [
        {
          name: 'skinnerWait',
          startTimeField: 'isCallTime',
          endTimeField: 'inProcedureTime',
          color: '#FFB800'
        }, 
        {
          name: 'skinner',
          startTimeField: 'inProcedureTime',
          endTimeField: 'endProcedureTime',
          color: '#9be599'
        }, 
        {
          name: 'stylistWait',
          startTimeField: 'endProcedureTime',
          endTimeField: 'stylistStartTime',
          color: '#ff9e9e'
        }, 
        {
          name: 'stylist-1',
          startTimeField: 'stylistStartTime',
          endTimeField: 'stylistEndTime',
          color: '#90aefd'
        },
        {
          name: 'stylist-2',
          startTimeField: 'stylistStartTime',
          endTimeField: 'stylistEndTime',
          color: '#90aefd'
        }
      ];

      let billItemsMap = {}
      
      this.teams.forEach(team => {
        // console.log('eval teams', team);
        stages.forEach(stage => {
          // console.log('eval stage', stage);
          let { startTimeField, endTimeField } = stage;

          const stylistIds = team.staff.filter(s => s.departmentId == 1).map(s => s.id);
          const skinnerIds = team.staff.filter(s => s.departmentId == 2).map(s => s.id);

          let filterFunc;
          switch (stage.name) {
            case 'skinnerWait': 
                filterFunc = bill => stylistIds.includes(bill.staffHairdresserId);
                break;
            case 'skinner':
                filterFunc = bill => skinnerIds.length && skinnerIds[0] == bill.staffHairMassageId;
                break;
            case 'stylistWait':
                filterFunc = bill => stylistIds.includes(bill.staffHairdresserId);
                break;
            case 'stylist-1':
                filterFunc = bill => stylistIds.length && stylistIds[0] == bill.staffHairdresserId;
                break;
            case 'stylist-2':
                filterFunc = bill => stylistIds.length > 1 && stylistIds[1] == bill.staffHairdresserId;
                break;
          }

          let bills = this.bills.filter(filterFunc);
          // console.log('bill after filter', bills.filter(b => !b.billId))

          let estimateFunc;

          switch (stage.name) {
            case 'skinnerWait': 
              estimateFunc = (startTime, bill) => {
                if (!skinnerIds.length) return null
                const customerEstimatedTime = moment.max([moment(startTime).add(5, 'minutes'), moment()]);
                const skinnerBills = this.bills.filter(b => b.staffHairMassageId == skinnerIds[0]);
                const sortedBills = skinnerBills.sort((a, b) => a.endProcedureTime < b.endProcedureTime ? 1 : -1);
                const skinnerAvailableTime = sortedBills.length && sortedBills[0].endProcedureTime ? moment(sortedBills[0].endProcedureTime) : moment().subtract(1, 'days');
                return moment.max([customerEstimatedTime, skinnerAvailableTime]).format('YYYY-MM-DDTHH:mm:ss');
              }
              break;
            case 'skinner':
              estimateFunc = (startTime, bill) => {
                const customerEstimatedTime = moment.max([moment(startTime).add(5, 'minutes'), moment()]);
                const estimateTime = this.getTotalEstimateTime(bill.serviceIds, 2);
                console.log('estimate service time', estimateTime);
                const endServiceEstimateTime = moment(startTime).add(estimateTime, 'minutes');

                return moment.max([customerEstimatedTime, endServiceEstimateTime]).format('YYYY-MM-DDTHH:mm:ss');
              }
              break;
            case 'stylistWait':
              estimateFunc = (startTime, bill) => {
                const customerEstimatedTime = moment.max([moment(startTime).add(5, 'minutes'), moment()]);
                const stylistBills = this.bills.filter(b => b.staffHairdresserId == bill.staffHairdresserId);
                const sortedBills = stylistBills.sort((a, b) => a.stylistEndTime < b.stylistEndTime ? 1 : -1);
                const stylistAvailableTime = sortedBills.length && sortedBills[0].stylistEndTime ? moment(sortedBills[0].stylistEndTime) : moment().subtract(1, 'days');
                return moment.max([customerEstimatedTime, stylistAvailableTime]).format('YYYY-MM-DDTHH:mm:ss');
              }
              break;
            case 'stylist-1':
            case 'stylist-2':
              estimateFunc = (startTime, bill) => {
                const customerEstimatedTime = moment.max([moment(startTime).add(5, 'minutes'), moment()]);
                const estimateTime = this.getTotalEstimateTime(bill.serviceIds, 1);
                // console.log('estimate service time', estimateTime);
                const endServiceEstimateTime = moment(startTime).add(estimateTime, 'minutes');

                return moment.max([customerEstimatedTime, endServiceEstimateTime]).format('YYYY-MM-DDTHH:mm:ss');
              }
              break;
          }

          let billItems = bills.map(b => {
              let startTime = b[startTimeField];
              let endTime = b[endTimeField];
              if (!startTime) return;
              const isPrediction = !endTime;
              if (isPrediction && this.isTodaySelected) {
                endTime = estimateFunc(startTime, b);
                this.updateBill({ bookingId: b.bookingId, field: endTimeField, value: endTime } )
                b[endTimeField] = endTime;
              }

              
              
              if (!startTime || !endTime) return;
              const baseTime = `${moment(this.timelineFilter.date).format('YYYY-MM-DD')} ${this.hours[0].hourFrame}`;
              const marginLeft = timeUtil.getTimeDiffPixels(baseTime, startTime);
              const width = timeUtil.getTimeDiffPixels(startTime, endTime);
              const minutes = timeUtil.getTimeMinutes(startTime, endTime);
              
              const billItem = {
                styleData: {
                  marginLeft,
                  width,
                  isPrediction,
                  minutes,
                  color: stage.color,
                  isFuture: moment(startTime).isAfter(moment())
                },
                id: b.bookingId,
                stage: stage.name.split('-')[0], // convert stylist-1, stylist-2 to stylist
                billData: {...b, team: team.teamKey},
                startTime,
                endTime,
                billCode: b.billCode
              }

              // if (isPrediction) console.log('predicted bill item', billItem);
              return billItem;
          })

          billItems = billItems.filter(i => i);

          const stageId = `${team.teamKey}-${stage.name}`;

          /*billItems = positionUtil.addVerticalPositions(billItems);

          const maxLayer = billItems.reduce((pv, cv) => Math.max(pv, cv.styleData.layer), 0);

          const stageId = `${team.teamKey}-${stage.name}`;

          this.updateStageData({
              stageId: stageId,
              field: 'maxLayer',
              value: maxLayer
          })*/

          billItemsMap[stageId] = billItems;
        })

        
      })

      Object.keys(billItemsMap).forEach(stageId => {
        let billItems = billItemsMap[stageId];

        billItems = positionUtil.addVerticalPositions(billItems);

        const maxLayer = billItems.reduce((pv, cv) => Math.max(pv, cv.styleData.layer), 0);

        //const stageId = `${team.teamKey}-${stage.name}`;

        this.updateStageData({
            stageId: stageId,
            field: 'maxLayer',
            value: maxLayer
        })

        billItemsMap[stageId] = billItems;
      })

      this.setBillItemsMap(billItemsMap);

      console.log('billItemsMap', billItemsMap);
    },

    initTimelineSocket(salonId) {
      console.log("initing timeline socket");
      this.socket = io.connect(
        `${
          process.env.VUE_APP_TIMELINE_SOCKET
        }/salon/${salonId}?accessToken=${localStorage.getItem("AccessToken")}`
      );

      this.socket.on("error", error => {
        console.log("Timeline websocket error", error);
      });

      this.socket.on(`booking`, async ({ event, booking }) => {
        if (!this.isTodaySelected) return;
        console.log(event, booking);
        switch (event) {
          case "post":
            this.$store.dispatch("addBooking", booking);
            break;
          case "put":
            this.$store.dispatch("updateBooking", booking);
            break;
          case "delete":
            this.$store.dispatch("removeBooking", booking);
            break;
        }
      });
    },

    disconnectSocket() {
      this.socket.disconnect();
    },
    getFullDepartmentServices(serviceIds, configDepartments) {
      if (!serviceIds) return [];
      let services = JSON.parse(serviceIds);

      let servicesFull = []

      services.forEach(s => {
          const sFull = this.services.find(i => i.id == s.Id);

          const isSkinnerService = sFull && configDepartments.includes(sFull.configDepartment);
          if (isSkinnerService) servicesFull.push(sFull);
      })

      return servicesFull;
    },
    getTotalEstimateTime(serviceIds, departmentId) {
      const configDepartments = departmentId == 1 ? [0, 1] : [0, 2];
      const estimateTimeField = departmentId == 1 ? 'stylistTimeEstimate' : 'skinnerTimeEstimate';
      const servicesFull = this.getFullDepartmentServices(serviceIds, configDepartments);
      const totalEstimateTime = servicesFull.reduce((p, c) => p + c[estimateTimeField], 0);
      return totalEstimateTime;
    },
  },

  async created() {
    this.$store.commit("updateSelectedSalon", this.user.listSalon);
    
  },

  watch: {
    async selectedSalon() {
      //console.log('ssssssssssss', this.timelineFilter.salon)
      console.log('selectedSalon change');
      await this.fetchData();
      if (this.socket && this.socket.connected) this.disconnectSocket();
      if (this.selectedSalon && this.selectedSalon.id) {
        this.initTimelineSocket(this.selectedSalon.id);

        await this.$store.dispatch("setRecognitionSocket", this.selectedSalon.id);
        if (this.enableFaceRecognition) this.listenToRecognition();
      }
    },

    async dateNormal() {
      await this.fetchData();
    }
  }
};
</script>

<style lang="scss">

.timeline {
  .timeline-data {
    border: 1px solid #eee;
    border-bottom: 0;
    border-right: 0;
  }

  .row {
    margin-bottom: 0 !important;
  }

  .data-table {
    overflow-x: auto;
    text-align: center;
    line-height: 2;
    padding: 10px 10px 0 0px;
    margin-top: 40px;
    // padding-top: 10px;
    // padding-right: 10px;
    .head {
      font-weight: bold;
      line-height: 3;
    }
  }
  .scroll {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 0;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #47494e;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #88b59c;
    }
    position: relative;
    height: 83vh;
  }

}
</style>