<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Linear Timeline</strong> by
        <a href="https://30shine.com">30shine IT Team</a>.
      </p>
    </div>
    <notifications group="foo" position="bottom right"/>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
.footer {
  padding: 1rem 1.5rem 1rem;
}
</style>