<template>
    <div class="row columns is-mobile is-gapless hour-container" @mousedown="$emit('mousedown')">
        <div 
            v-for="(hour, i) in hoursNew" 
            :key="hour + i" 
            :id="`hour-${hour}`"
            :class="wapperClass(hour)"
            data-dragscroll="true"
        >
            {{hour}}
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import timeUtil from '@/utils/time';

const convertHour = ( hour ) =>{
    const _hour = hour.split('h');
    return [ _hour[0] + 'h' + (Number(_hour[1]) + 5), _hour[0] + 'h' + (Number(_hour[1]) + 10)]
}

export default {
    name: "HourRow",
    props: ['is20Minutes'],
    data() {
        return {
            isFirstTime: true
        }
    },
    computed: {
        ...mapGetters(['hours']),
        /*hours() {
            let hours = this.$store.getters.hours;
            console.log(hours)
            return hours;
        }*/
        hoursNew(){
            var list = [];
            this.hours.map((item)=>{
                 const hour = convertHour(item.hour);
                 list.push(item.hour);
                 list.push(hour[0]);
                 list.push(hour[1]);
            })
            return list;
        }
       
    },
    methods: {
        wapperClass(value){
            return  Number(value.split('h')[1]) % 15 === 0 ? 'hour-cell' : 'hour-cell hour-cell-small';
        },
        gotoNow() {
            if (this.isFirstTime && this.hours && this.hours.length) {
                let goto = timeUtil.getNearest15Minutes();
                this.isFirstTime = false;
                const maxHourId = 'hour-' + this.hours[this.hours.length - 1].hour;
                if (goto.length == maxHourId.length && goto > maxHourId) goto = maxHourId;
                this.$scrollTo(document.getElementById(goto), 10, {})
            }
        }
    },
    mounted: function () {
        /*this.$nextTick(function () {
            this.gotoNow();
        })*/
    },
    created() {
        
    }
}
</script>

<style scoped lang="scss">
$cell-5-width: 270px;

.hour-cell {
  height: 40px;
  width: $cell-5-width / 3;
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  font-size: 18px;
}
.hour-cell-small{
    font-size: 15px;
    line-height: 35px;
    color: #5d5b5b;
}
.hour-container {
  cursor: pointer;
  display: flex;
  margin-left: -45px;
  font-weight: bold;
  z-index: 199;
  background: #fff;
  position: absolute;
  top: 0;
}
</style>
