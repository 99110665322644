
const isTimeBetween = (start, end, val) => {
    return val > start && val < end
}

const addVerticalPositions = (billItems) => {
    let sortedBillItems = [];

    for (let billItem of billItems) {
        let layer = 0;
        while (!billItem.styleData.layer) {
            layer++;
            const billsInLayer = sortedBillItems.filter(b => b.styleData.layer == layer);
            
            let isLayerValid = true;
            for (let b of billsInLayer) {
                if (
                    isTimeBetween(b.startTime, b.endTime, billItem.startTime) 
                    || isTimeBetween(b.startTime, b.endTime, billItem.endTime)
                    || isTimeBetween(billItem.startTime, billItem.endTime, b.startTime)
                    || isTimeBetween(billItem.startTime, billItem.endTime, b.endTime)
                ) isLayerValid = false;
            }

            if (isLayerValid) billItem.styleData.layer = layer;
        }

        sortedBillItems.push(billItem);
    }

    return sortedBillItems;
}
 
export default {
    addVerticalPositions
}