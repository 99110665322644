var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.stage + '-' + _vm.billData.bookingId + ' ' + _vm.wapperClass,style:({
        width: _vm.styleData.width + 'px', 
        left: _vm.styleData.marginLeft + 'px', 
        top: (_vm.styleData.layer - 1) * 25 + 'px',
        //backgroundColor: styleData.isPrediction ? '#37474f' : styleData.color
        // backgroundColor: styleData.isFuture ? 'white' : styleData.color,
        backgroundColor: _vm.backgroundColor,
        border: _vm.styleData.isFuture && !_vm.billData.isCallTimePredicted ? ("4px solid " + (_vm.styleData.color)) : 'none'
    }),on:{"mouseover":_vm.generateConnectingLines,"mouseleave":_vm.removeConnectingLines}},[_c('div',{staticClass:"bill-number"},[_c('b',[_vm._v(_vm._s(_vm.billNumber))]),(_vm.isBillHover)?_c('div',{staticClass:"tooltip"},[_vm._v("\n            "+_vm._s(_vm.styleData.minutes)+" phút\n        ")]):_vm._e(),((_vm.stage == 'skinner' || _vm.stage == 'stylist') && _vm.isBillHover)?_c('div',{staticClass:"bill-info-popover"},[_c('div',{staticClass:"customer-popover-info"},[_c('b',[_vm._v(_vm._s(_vm.billData.customerName))]),_c('b',{staticStyle:{"margin-left":"auto"}},[_vm._v(_vm._s(_vm.billData.customerCode))])]),_c('div',{staticClass:"book-hour-container"},[_c('b',[_vm._v("Giờ book: ")]),_vm._v(" "+_vm._s(_vm.bookHourText))]),_c('div',{staticClass:"services-container"},[_c('b',[_vm._v("Dịch vụ:")]),_vm._l((_vm.getStaffService(_vm.stage)),function(service){return _c('div',{key:service.id},[_vm._v("\n                    "+_vm._s(service.serviceName)+"\n                ")])})],2)]):_vm._e()]),(!_vm.styleData.isFuture)?_c('div',{staticClass:"customer-info"},[_c('b',[_vm._v(_vm._s(_vm.billData.customerName.toLowerCase())+" - ")]),_c('b',[_vm._v(_vm._s(_vm.last4PhoneDigits))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }