<template>
  <div class="team-column-container">
    <TeamItem v-for="team in teams" :key="team.teamKey" :team="team" class="team-item"/>
      
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from "vuex";

import TeamItem from '@/components/Timeline/TeamItem';

export default {
  name: "TeamColumn",
  components: {
    TeamItem
  },
  computed: {
    ...mapGetters(['teams']),
    
  },
  created() {
    
  }
};
</script>

<style scoped lang="scss">
.team-item {
  margin-bottom: 30px;
}

.team-column-container {
  height: 75vh;
  overflow-y: hidden;
  min-width: 275px;
  margin-right: 20px;
}
</style>
