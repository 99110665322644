<template>
    <div>
        
        <div class="team-bills-container" v-if="isTeamActive[team.teamId]">
            <div class="bill-items-container bill-items-wait" :style="{ height: stageHeight('skinnerWait') }">
                <BillItem v-for="billItem in billItemsMap[`${team.teamKey}-skinnerWait`]" :key="billItem.id" :stage="billItem.stage" :billData="billItem.billData" :styleData="billItem.styleData"/>
                <div class="book-hour" v-if="isBookHour" :style="{left: billHover.styleBookHour.left, top: billHover.styleBookHour.top}">
                    <img src="@/assets/img/icon-book.png">
                </div>
            </div>
            
            <div class="bill-items-container" v-if="skinners.length" :style="{ height: stageHeight('skinner') }">
                <BillItem v-for="billItem in billItemsMap[`${team.teamKey}-skinner`]" :key="billItem.id" :stage="billItem.stage" :billData="billItem.billData" :styleData="billItem.styleData"/>
            </div>
            <div class="bill-items-container bill-items-wait" :style="{ height: stageHeight('stylistWait') }">
                <BillItem v-for="billItem in billItemsMap[`${team.teamKey}-stylistWait`]" :key="billItem.id" :stage="billItem.stage" :billData="billItem.billData" :styleData="billItem.styleData"/>
            </div>
            <div class="bill-items-container" v-if="stylists.length" :style="{ height: stageHeight('stylist-0') }">
                <BillItem v-for="billItem in billItemsMap[`${team.teamKey}-stylist-1`]" :key="billItem.id" :stage="billItem.stage" :billData="billItem.billData" :styleData="billItem.styleData"/>
            </div>
            <div class="bill-items-container" v-if="stylists.length > 1" :style="{ height: stageHeight('stylist-1') }">
                <BillItem v-for="billItem in billItemsMap[`${team.teamKey}-stylist-2`]" :key="billItem.id" :stage="billItem.stage" :billData="billItem.billData" :styleData="billItem.styleData"/>
            </div>
        </div>
        <div v-else style="height: 25px;"></div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';


import BillItem from '@/components/Timeline/BillItem';
import timeUtil from '@/utils/time';
import positionUtil from '@/utils/position';

export default {
    name: "TeamBills",
    props: ['team', ],
    data() {
        return {
            
        }
    },
    components: {
        BillItem
    },
    computed: {
        ...mapGetters(['bills', 'hours', 'stageData', 'services', 'billItemsMap', 'billHover', 'isTeamActive']),
        skinners() {
            return this.team.staff.filter(s => s.departmentId == 2)
        },
        stylists() {
            return this.team.staff.filter(s => s.departmentId == 1)
        },
        /*stylistIds() {
            return this.stylists.map(s => s.id);
        },*/
        stageHeight() {
            return stageName => {
                const stageId = `${this.team.teamKey}-${stageName}`;
                const maxLayer = this.stageData[stageId] ? this.stageData[stageId].maxLayer : 0;
                const height = Math.max(maxLayer * 25, 40);
                return height + 'px';
            }
        },
        isBookHour(){
           return this.billHover.bookingId && this.billHover.styleBookHour.team === this.team.teamKey;
        }
    }
}
</script>

<style scoped lang="scss">
.bill-team-item {
  margin-bottom: 30px;
}

.bill-items-container {
    // height: 40px;
    //min-height: max-content;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;

    background-color: #f7f7f7;
    &.bill-items-wait {
        background-color: #e5e5e5;
    }
    .book-hour{
    position: absolute;
    top: 0;
    z-index: 3;
       img{
           height: 25px;
       }
    }
}
</style>
