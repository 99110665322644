<template>
    <div class="team-container">
        <div class="team-number" 
            :class="{ 'is-inactive': !isTeamActive[team.teamId] }"
            @click="updateIsTeamActive({ teamId: team.teamId, isActive: !isTeamActive[team.teamId] })"
            >
            {{ team.teamId }}
        </div>
        <div class="team-steps-container" v-if="isTeamActive[team.teamId]">
            <div class="team-step" :style="{ height: stageHeight('skinnerWait') }">
                <div class="team-step-info team-step-info-wait">
                    <div class="color-marker color-wait-massaging"></div>
                    <b>Đợi gội</b>
                </div>
            </div>
            <div class="team-step" v-if="skinners.length" :style="{ height: stageHeight('skinner') }">
                <div class="team-step-info">
                    <div class="color-marker color-massaging"></div>
                    <b>Gội</b>
                    <div class="staff-info">
                        {{ skinners[0].fullName }} ({{ skinners[0].id }})
                    </div>
                </div>
            </div>
            <div class="team-step" :style="{ height: stageHeight('stylistWait') }">
                <div class="team-step-info team-step-info-wait">
                    <div class="color-marker color-wait-hairstyling"></div>
                    <b>Đợi cắt</b>
                    
                </div>
            </div>
            <div class="team-step" v-if="stylists.length" :style="{ height: stageHeight('stylist-0') }">
                <div class="team-step-info">
                    <div class="color-marker color-hairstyling"></div>
                    <b>Cắt</b>
                    <div class="staff-info">
                        {{ stylists[0].fullName }} ({{ stylists[0].id }})
                    </div>
                </div>
            </div>
            <div class="team-step" v-if="stylists.length > 1" :style="{ height: stageHeight('stylist-1') }">
                <div class="team-step-info">
                    <div class="color-marker color-hairstyling"></div>
                    <b></b>
                    <div class="staff-info">
                        {{ stylists[1].fullName }} ({{ stylists[1].id }})
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

import BillItem from '@/components/Timeline/BillItem';

export default {
    name: "TeamItem",
    props: ['team'],
    components: {
        BillItem
    },
    data() {
        return {
            skinners: this.team.staff.filter(s => s.departmentId == 2),
            stylists: this.team.staff.filter(s => s.departmentId == 1)
        }
    },
    computed: {
        ...mapGetters(['bills', 'stageData', 'isTeamActive']),
        stageHeight() {
            return stageName => {
                const stageId = `${this.team.teamKey}-${stageName}`;
                const maxLayer = this.stageData[stageId] ? this.stageData[stageId].maxLayer : 0;
                const height = Math.max(maxLayer * 25, 40);
                return height + 'px';
            }
        }
    },
    methods: {
        ...mapMutations(['updateIsTeamActive'])
    },
    created() {
    }
}
</script>

<style scoped lang="scss">

.team-container {
    display: flex;
    flex-direction: row;
}

.team-steps-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.team-number {
    width: 25px;
    min-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #808080;
    color: white;
    cursor: pointer;

    &.is-inactive {
        width: 275px;
    }
}

.team-step {
    // height: 40px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}

.team-step:last-child {
    margin-bottom: 0;
}

.team-step-info {
    background-color: #f7f7f7;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: row;
    
    align-items: center;

    &.team-step-info-wait {
        background-color: #e5e5e5;
    }

    .staff-info {
        justify-self: right;
        align-self: right;
        margin-left: auto;
        margin-right: 10px;
    }
}



.color-marker {
    width: 15px;
    height: 100%;
    margin-right: 10px;
}

.color-wait-massaging {
    background-color: #FFB800
}

.color-massaging {
    background-color: #9be599
}

.color-wait-hairstyling {
    background-color: #ff9e9e
}

.color-hairstyling {
    background-color: #90aefd
}

.bill-items-container {
    position: relative;
}
</style>
