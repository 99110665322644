import moment from 'moment';

const getNearest15Minutes = () => {
    const now = moment().subtract(15, 'minutes');
    let currentHour = now.hours();
    let currentMinute = now.minutes();
    let minute = currentMinute - currentMinute % 15;
    let result = 'hour-' + currentHour + 'h' + (minute < 10 ? "0" + minute : minute);
    return result;
}

const isToday = (date) => {
    const today = new Date();
    return date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
}

const PIXEL_PER_MINUTE = 270/15;

const getTimeDiffPixels = (startTime, endTime) => {
    const pixels = PIXEL_PER_MINUTE * Math.max(moment.duration(moment(endTime).diff(moment(startTime))).asMinutes(), 1);
    return pixels;
}

const getTimeMinutes = (startTime, endTime) => {
    const mimutes = moment.duration(moment(endTime).diff(moment(startTime))).asMinutes()
    return Math.round(mimutes);
}

const getCurrentWorkHour = workHours => {
    const nowHourFrame = moment().format('HH:mm:ss');
    var currentWorkHour = workHours.find((h, i) => h.hourFrame <= nowHourFrame && (!workHours[i + 1] || workHours[i + 1].hourFrame > nowHourFrame));
    return currentWorkHour;
}

export default { 
    getNearest15Minutes,
    isToday,
    getTimeDiffPixels,
    getTimeMinutes,
    getCurrentWorkHour
}
