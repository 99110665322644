<template>
    <div 
        :class="stage + '-' + billData.bookingId + ' ' + wapperClass" 
        :style="{
            width: styleData.width + 'px', 
            left: styleData.marginLeft + 'px', 
            top: (styleData.layer - 1) * 25 + 'px',
            //backgroundColor: styleData.isPrediction ? '#37474f' : styleData.color
            // backgroundColor: styleData.isFuture ? 'white' : styleData.color,
            backgroundColor: backgroundColor,
            border: styleData.isFuture && !billData.isCallTimePredicted ? `4px solid ${styleData.color}` : 'none'
        }"
        @mouseover="generateConnectingLines"
        @mouseleave="removeConnectingLines"
    >
        <div class="bill-number">
            <b>{{ billNumber }}</b>
            <div class="tooltip" v-if="isBillHover">
                {{styleData.minutes}} phút
            </div>
            <div class="bill-info-popover" v-if="(stage == 'skinner' || stage == 'stylist') && isBillHover">
                <div class="customer-popover-info">
                    <b>{{ billData.customerName }}</b>
                    <b style="margin-left: auto">{{ billData.customerCode }}</b>
                </div>
                <div class="book-hour-container"><b>Giờ book: </b>&nbsp;{{bookHourText}}</div>

                <!--<div style="display: flex; flex-direction: column; align-items: flex-start;">-->
                <div class="services-container">
                    <!--<b-field label="Dịch vụ:" custom-class="is-small">
                        
                    </b-field>-->
                    <b>Dịch vụ:</b>
                    
                    <div v-for="service in getStaffService(stage)" :key="service.id">
                        {{ service.serviceName }}
                    </div>
                </div>
                <!--</div>-->
                
            </div>
        </div>
        <!--<div>{{ billData.id }}</div>-->
        <div class="customer-info" v-if="!styleData.isFuture"><!-- v-if="!styleData.isPrediction" -->
            <b>{{ billData.customerName.toLowerCase() }} - </b>
            <b>{{ last4PhoneDigits }}</b>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';

import convertUtil from '@/utils/convert';

function diffHourToPixel( booking, start ){
    const _booking = booking.split('h');
    const _start = start.split('h');
    return (( Number(_booking[0]) - Number(_start[0])) * 60 + Number(_booking[1]) - Number(_start[1])) * 18 - 12 + 'px';
}

export default {
    name: "BillItem",
    props: ['billData', 'styleData', 'color', 'stage'],
    data() {
        return {
            lines: []
        }
    },
    computed: {
        ...mapGetters(['billHover', 'hours', 'services']),
        billNumber() {
            return convertUtil.getBillNumberFromBillCode(this.billData.billCode)
        },
        last4PhoneDigits() {
            return convertUtil.getLast4PhoneDigits(this.billData.customerCode)
        },
        ...mapGetters(['billHover', 'hours']),
        isBillHover(){
            return this.billData.bookingId === this.billHover.bookingId;
        },
        wapperClass(){
            return  this.isBillHover ?  'bill-container ishover' : 'bill-container'
        },
        bookHourText() {
            let hourItem;
            if (this.billData.secondSubHourId) {
                hourItem = this.hours.find(h => h.secondSubHourId == this.billData.secondSubHourId);
            } else {
                hourItem = this.hours.find(h => h.subHourId == this.billData.subHourId)
            }
            return hourItem.hour;
        },
        backgroundColor() {
            if (this.billData.isCallTimePredicted) return '#37474f';
            if (this.styleData.isFuture) return 'white';
            return this.styleData.color;
        }
    },
    methods: {
        ...mapMutations(['addConnectingLines', 'clearConnectingLines', 'setBillHover', 'clearBillHover']),
        generateConnectingLines() {
            const stages = ['skinnerWait', 'skinner', 'stylistWait', 'stylist'];
            const bookingId = this.billData.bookingId;
    
            let lines = []

            for (let i = 0; i < stages.length - 1; i++) {
                const start = document.getElementsByClassName(`${stages[i]}-${bookingId}`)[0];
                const end = document.getElementsByClassName(`${stages[i+1]}-${bookingId}`)[0];

                if (!start || !end) continue;
                
                const line = new LeaderLine(
                    start,
                    end,
                    { zIndex: 3}
                );
                lines.push(line)
            }
         
            this.setBillHover({bookingId : bookingId, styleBookHour: 
                { top : (this.styleData.layer - 1) * 25 + 'px', 
                  team: this.billData.team,
                  left: diffHourToPixel(this.bookHourText, this.hours[0].hour)
                } 
            })
            this.addConnectingLines(lines);
        },
        removeConnectingLines() {
            this.clearConnectingLines();
            this.clearBillHover();
        },
        getFullDepartmentServices(serviceIds, configDepartments) {
            if (!serviceIds) return [];
            let services = JSON.parse(serviceIds);

            let servicesFull = []

            services.forEach(s => {
                const sFull = this.services.find(i => i.id == s.Id);

                const isSkinnerService = sFull && configDepartments.includes(sFull.configDepartment);
                if (isSkinnerService) servicesFull.push(sFull);
            })

            return servicesFull;
        },
        getStaffService(department) {
            const configDepartments = department == 'stylist' ? [0, 1] : [0, 2];
            const servicesFull = this.getFullDepartmentServices(this.billData.serviceIds, configDepartments);
            return servicesFull;
        }
    },
    created() {
    }
}
</script>

<style scoped lang="scss">
.bill-container {
    position: absolute;
    height: 22px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    transition: height .2s, box-shadow .2s;
}
.ishover{
    box-shadow: 6px 6px 4px #4e4e4e;
    height: 25px;
    z-index: 2;
}

.bill-number {
    min-width: 15px;
    min-height: 15px;
    border-radius: 100%;
    background-color: white;
    text-align: center;
    line-height: 15px;
    margin: 0 5px;
    font-size: 12px;
    position: relative;
     .tooltip{
        position: absolute;
        top: -28px;
        left: -5px;
        background: #111;
        border-radius: 7px;
        box-shadow: 2px 2px 2px rgb(116, 114, 114);
        color: #fff;
        padding: 3px 15px;
        width: max-content;
        font-size: 14px;
        opacity: .8;
    }

    
}

.bill-info-popover {
    position: absolute;
    // width: max-content;
    width: 200px;
    top: 25px;
    background: #fff;
    border: 1px solid #333;
    border-radius: 3px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    //align-items: flex-start;

    .customer-popover-info {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
    }

    .book-hour-container {
        display: flex;
        margin-bottom: 5px;
    }

    .services-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.customer-info {
    margin-left: auto;
    margin-right: 5px;
    min-width: max-content;
    text-transform: capitalize;
}
</style>
