<template>
    <div class="bills-table">
        <TeamBills v-for="team in teams" :key="team.teamKey" :team="team" class="bill-team-item"/>
        <div class="divider-container" v-if="hours.length">
            <div v-for="i in hours.length * 3" :key="i" class="divider" :style="{left: i * 270 / 3 + 'px'}"></div>
        </div>
        <div class="currentTime" :style="{width: currentTime}">
        </div>
        <div class="line-time" :style="{left: currentTime}">
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TeamBills from '@/components/Timeline/TeamBills';

export default {
    name: "BillsTable",
    props: ['bills'],
    data() {
        return {
            
        }
    },
    components: {
        TeamBills
    },
    computed: {
        ...mapGetters(['teams', 'hours', 'timelineFilter']),
        currentTime (){
            if(this.hours.length){
                const date = new Date();
                const _hour = this.hours[0].hour.split('h');
                const value = ((date.getHours() -  Number(_hour[0])) * 60  + date.getMinutes() - Number(_hour[1])) *18;
                document.getElementsByClassName('scroll')[0].scrollLeft = value - 30 * 18;
                return value + 'px';
            }  
        }
    },
    methods: {
    },
    created() {
    }
}
</script>

<style scoped lang="scss">
.bill-team-item {
  margin-bottom: 30px;
}

.divider-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0px;
    // height: 100%;
    height: 100%;
}

.divider {
    position: absolute;
    width: 1px;
    background-color: #cfd8dc;
    height: 100%;
}

.bills-table {
    position: relative;
}

.currentTime{
    position: absolute;
    top: 0;
    height: 100%;
    background: #111;
    opacity: .1;
}
.line-time{
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    border-right: 1px solid #111; 
}
</style>
