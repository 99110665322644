import axios from 'axios';

import apiConfig from '@/configs/api';

import convertUtil from '@/utils/convert';

const state = {
    bookings: [],
    completedBillByStylist: {},
    shouldDeferAction: {}
}

const getters = {
    bookings: state => state.bookings,
    getBooking: state => id => {
        for (let i = 0; i < state.bookings.length; i++) {
            if (id === state.bookings[i].id) {
                return state.bookings[i]
            }
        }
        return false
    },
    bookingMap: state => {
        let m = {};
        state.bookings.forEach(booking => {
            m[booking.id] = booking;
        })
        return m;
    },
    completedBillByStylist: state => state.completedBillByStylist,
    shouldDeferAction: state => state.shouldDeferAction
}

const actions = {
    async setBookings({ commit }, { salonId, date }) {
        console.time('setBookings')
        let { data: bookings } = await axios.get(`${apiConfig.MAIN_API}/api/booking?salonId=${salonId}&dateBook=${date.format("YYYY-MM-DD")}`);
        bookings = bookings.map(booking => convertUtil.transformSubHourIn(booking));
        commit('setBookings', bookings);
        bookings.forEach(booking => {
            if (booking.completeBillTime) commit('addCompletedBill', booking);
        });
        console.timeEnd('setBookings')
    },

    updateBookingsToTimeline({ state, dispatch }) {
        if (typeof state.bookings != "string")
            state.bookings.forEach(booking => {
                dispatch("addToTimeline", booking, { root: true });
            });
    },

    async addBooking({ commit, dispatch }, booking) {
        //await axios.post(`${apiConfig.NEW_API}/api/timeline`, booking);
        booking = convertUtil.transformSubHourIn(booking);
        commit('addBooking', booking);
        dispatch('addToTimeline', booking, { root: true });
        
        // if (booking.completeBillTime) commit('addCompletedBill', booking);
    },
    removeBooking({ commit, dispatch }, booking) {
        booking = convertUtil.transformSubHourIn(booking);
        if (!booking.stylistId && !booking.subHourId)
            dispatch("removeFromTimelineById", booking.id, { root: true });
        else dispatch("removeFromTimeline", booking, { root: true });
        commit('removeBooking', booking);
    },
    async updateBooking({ commit, dispatch }, booking) {
        booking = convertUtil.transformSubHourIn(booking);
        await dispatch('updateTimeline', booking, { root: true })
        commit('updateBooking', booking);
    },

    async updateIsCall({ commit }, { bookingId, isCall }) {
        console.time('updateIsCall');
        
        // commit('setDeferAction', { bookingId, shouldDefer: true });
        await axios.put(`${apiConfig.MAIN_API}/api/booking/update-iscall?bookingId=${bookingId}&isChecked=${isCall}`);
        // commit('setDeferAction', { bookingId, shouldDefer: false });

        console.timeEnd('updateIsCall');
    },
    async updateIsBookStylist({ commit }, { bookingId, isBookStylist }) {
        axios.put(`${apiConfig.MAIN_API}/api/booking/update-is-book-stylist?bookingId=${bookingId}&isBookStylist=${isBookStylist}`);
    },

    /*updateBookingInfo({ commit }, booking) {

    }*/
}

const mutations = {
    setBookings(state, bookings) {
        state.bookings = bookings
    },
    addBooking(state, booking) {
        state.bookings.push(booking)
    },
    removeBooking(state, booking) {
        for (let i = 0; i < state.bookings.length; i++) {
            if (state.bookings[i].id === booking.id) {
                state.bookings.splice(i, 1)
            }
        }
    },
    updateBooking(state, booking) {
        for (let i = 0; i < state.bookings.length; i++) {
            if (state.bookings[i].id === booking.id) {
                // state.bookings.splice(i, 1, booking);
                const newBooking = {
                    ...state.bookings[i],
                    ...booking
                }
                state.bookings.splice(i, 1, newBooking);
            }
        }
    },
    addCompletedBill(state, booking) {
        const currentVal = state.completedBillByStylist[booking.stylistId] || 0;
        
        state.completedBillByStylist = {
            ...state.completedBillByStylist,
            [booking.stylistId]: currentVal + 1
        }
    },
    setDeferAction(state, { bookingId, shouldDefer }) {
        state.shouldDeferAction[bookingId] = shouldDefer;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
