<template>
  <div class="level-left">
    <div class="level-item">
      <h4 class="is-bold is-uppercase">Timeline</h4>
    </div>
    <div class="level-item">
      <b-field v-if="this.user.listSalon">
        <b-autocomplete
          size="is-small"
          :open-on-focus="true"
          v-model="salonName"
          placeholder="Chọn Salon"
          :data="filteredSalon"
          field="salonName"
          @select="onSalonSelect"
          @focus="onSalonFocus"
          class="filter-input"
        >
        
        </b-autocomplete>
      </b-field>
    </div>
    <div class="level-item">
      <b-datepicker
        size="is-small"
        v-model="date"
        placeholder="Chọn ngày"
        icon="calendar-today"
        :date-parser="(date) => new Date(Date.parse(date))"
        :readonly="false"
        :min-date="minDate"
        :max-date="maxDate"
        class="filter-input"
      ></b-datepicker>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";



const generateComputedFilter = (filterName, _this) => {
  return {
    get() {
      return _this.timelineFilter[filterName];
    },
    set(value) {
      _this.$store.commit("updateTimelineFilter", { name: filterName, value });
    }
  };
};

export default {
  name: "FilterBar",
  data() {
    const today = new Date()
    return {
      salonName: "",
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 3),
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      prevSalonName: ""
    };
  },
  methods: {
    onSalonBlur() {
      console.log('on blur');
      console.log('prev', this.prevSalonName)
      if (this.salonName.length == 0 && this.prevSalonName.length > 0) {
        this.salonName = this.prevSalonName;
      }
      this.prevSalonName = "";
    },
    onSalonFocus() {
      this.prevSalonName = this.salonName;
      this.salonName = '';
    },
    onSalonSelect(option) {
      console.log('on select');
      this.prevSalonName = ""; 
      this.salon = option;
    }
  },  
  computed: {
    ...mapGetters(["user", "timelineFilter", "recognitionError", "recognitionSocket"]),
    salon: {
      get() {
        if (this.timelineFilter.salon) 
          this.salonName = this.timelineFilter.salon.salonName;
        return this.timelineFilter.salon;
      },
      set(value) {
        localStorage.setItem("salonId", value.id);
        this.$store.commit("updateTimelineFilter", { name: "salon", value });
      }
    },
    date: {
      get() {
        return this.timelineFilter.date;
      },
      set(value) {
        localStorage["date"] = JSON.stringify(value);
        this.$store.commit("updateTimelineFilter", { name: "date", value });
      }
    },
    enableFaceRecognition: {
      get() {
        return this.timelineFilter.enableFaceRecognition;
      },
      set(value) {
        localStorage["enableFaceRecognition"] = JSON.stringify(value);
        this.$store.commit("updateTimelineFilter", {
          name: "enableFaceRecognition",
          value
        });
      }
    },
    minAccuracy: {
      get() {
        return this.timelineFilter.minAccuracy;
      },
      set(value) {
        localStorage["minAccuracy"] = JSON.stringify(value);
        this.$store.commit("updateTimelineFilter", {
          name: "minAccuracy",
          value
        });
      }
    },
    filteredSalon() {
      return this.user.listSalon.filter(
        option =>
          option.salonName
            .toString()
            .toLowerCase()
            .indexOf(this.salonName.toLowerCase()) >= 0
      );
    },
    minAccuracyOptions() {
      let options = [];
      for (let i = 65; i <= 95; i++)
        options.push({
          value: i,
          id: i
        });
      return options;
    }
  },
  watch: {
    salon: (newVal, _) => {}
  }
};
</script>

<style scoped>
.toggle-recognition {
  margin-left: 0.5srem;
}

.recognition-error {
  cursor: pointer;
}

.filter-input {
  width: 120px;
}


</style>

<style>
.autocomplete .dropdown-item, .autocomplete .dropdown .dropdown-menu .has-link a, .dropdown .dropdown-menu .has-link .autocomplete a {
  overflow: visible;
}
</style>
