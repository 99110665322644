
const dfBillHover = {bookingId: 0, styleBookHour: {top: 0, team: 0}}

const state = {
    selectedBookingId: 0,
    isModalActive: {
        bookNote: false,
        printBill: false,
        bookForm: false,
        organizeSkinner: false
    },
    isDragging: false,
    isEditingCustomer: false,
    connectingLines: [],
    stageData: {},
    billHover: dfBillHover
}

const getters = {
    selectedBookingId: state => state.selectedBookingId,
    isModalActive: state => state.isModalActive,
    isDragging: state => state.isDragging,
    isEditingCustomer: state => state.isEditingCustomer,
    connectingLines: state => state.connectingLines,
    stageData: state => state.stageData,
    billHover: state => state.billHover
}

const actions = {

}

const mutations = {
    setModalActive(state, { type, isActive }) {
        state.isModalActive[type] = isActive;
    },
    openBookingModal(state, { bookingId, type }) {
        state.selectedBookingId = bookingId;
        state.isModalActive[type] = true;
    },
    setDragging(state, isDragging) {
        state.isDragging = isDragging;
    },
    setEditingCustomer(state, isEditingCustomer) {
        state.isEditingCustomer = isEditingCustomer;
    },
    clearConnectingLines(state) {
        state.connectingLines.forEach(line => line.remove())
        state.connectingLines = []
    },
    addConnectingLines(state, lines) {
        state.connectingLines = [...state.connectingLines, ...lines]
    },
    updateStageData(state, { stageId, field, value }) {
        if (state.stageData[stageId] && state.stageData[stageId][field] == value) return;
        const updatedStage = {
            ...state.stageData[stageId],
            [field]: value
        }
        state.stageData = {
            ...state.stageData,
            [stageId]: updatedStage
        }
    },
    setBillHover(state, billHover) {
        state.billHover = billHover ;
    },
    clearBillHover(state) {
        state.billHover = dfBillHover;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
