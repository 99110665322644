
import apiConfig from '@/configs/api'

import axios from 'axios';

import convertUtil from '@/utils/convert';
import storageUtil from '@/utils/storage';

const state = {
    services: [],
    products: [],
    experimentSalonIds: [],
    workHours: []
}

const getters = {
    services: state => state.services,
    products: state => state.products,
    workHours: state => state.workHours,
    isExperimentSalon: state => salonId => state.experimentSalonIds.includes(salonId),
    
    indexedServices: state => {
        const indexed = state.services.map(item => ({
            ...item,
            normalizedName: convertUtil.removeVietnameseAccent(item.serviceName.toLowerCase()) + ' ' + item.id,
            displayName: `${item.serviceName} (${item.id})`
        }))
        return indexed;
    },
    indexedProducts: state => {
        const indexed = state.products.map(item => ({
            ...item,
            normalizedName: convertUtil.removeVietnameseAccent(item.name.toLowerCase()) + ' ' + item.id,
            displayName: `${item.name} (${item.id})`
        }))
        return indexed;
    },
    getIndexedServiceById: (state, getters) => serviceId => {
        const results = getters.indexedServices.filter(item => item.id == serviceId);
        return results.length ? results[0] : null;
    },
}

const actions = {
    async setServices({ commit }) {
        const getUrl = `${apiConfig.API_TIMELINE}/api/service`;
        let services = await storageUtil.getStaticData(`services`, getUrl);
        
        commit('setServices', services)
    },
    async setProducts({ commit }) {
        const getUrl = `${apiConfig.MAIN_API}/api/product`;
        let products = await storageUtil.getStaticData(`products`, getUrl);
        commit('setProducts', products)
    },
    async setLinearFlowExperimentSalons({ commit }) {
        const { data: experimentSalonIds } = await axios.get(`${apiConfig.MAIN_API}/api/bill-service/linear-flow-experiment-salons`);
        commit('setExperimentSalonIds', experimentSalonIds);
    },
    async setWorkHours({ commit }, salonId) {
        const getUrl = `${apiConfig.MAIN_API}/api/get-staff/salon-work-hours?salonId=${salonId}`;
        let workHours = await storageUtil.getStaticData(`work-hours-${salonId}`, getUrl);
        commit('setWorkHours', workHours);
    }
}

const mutations = {
    setServices(state, services) {
        state.services = Object.freeze(services);
    },
    setProducts(state, products) {
        state.products = Object.freeze(products);
    },
    setExperimentSalonIds(state, experimentSalonIds) {
        state.experimentSalonIds = experimentSalonIds;
    },
    setWorkHours(state, workHours) {
        state.workHours = Object.freeze(workHours);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
