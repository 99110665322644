
import axios from 'axios';

import apiConfig from '@/configs/api';

const state = {
    billMap: {},
    bills: [],
    billItemsMap: {}
}

const getters = {
    billMap: state => state.billMap,
    billByBookingId: state => {
        let m = {};
        Object.keys(state.billMap).forEach(billId => {
            const bill = state.billMap[billId];
            m[bill.bookingId] = bill;
        })
        return m;
    },
    bills: state => state.bills,
    billItemsMap: state => state.billItemsMap
}

const actions = {
    async setBills({ commit }, { salonId, date }) {
        let { data: bills } = await axios.get(`${apiConfig.MAIN_API}/api/bill-service/bill-items?salonId=${salonId}&date=${date.format("YYYY-MM-DD")}`);
        commit('setBills', bills)
    }
}

const mutations = {
    setBillMap(state, bill) {
        state.billMap = {
            ...state.billMap,
            [bill.id]: bill
        }
    },
    setBills(state, bills) {
        state.bills = bills;
    },
    setBillItemsMap(state, billItemsMap) {
        state.billItemsMap = billItemsMap;
    },
    updateBill(state, { bookingId, field, value }) {
        const billI = state.bills.findIndex(b => b.bookingId == bookingId);
        state.bills.splice(billI, 1, {
            ...state.bills[billI],
            [field]: value
        })
    },
    resetBills(state, bills) {
        state.bills = bills;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
