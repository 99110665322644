<template>
  <div class="fix-hour">
    {{hour}}h
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FixHourHeader",
  props: ['hour']
};
</script>

<style scoped lang="scss">
$cell-5-width: 270px;

.fix-hour {
  position: absolute;
  height: 40px;
  width: $cell-5-width / 3;
  border-bottom: 1px solid #f5f5f5;
  font-size: 18px;
  line-height: 2;
  left: 278px;
  font-weight: bold;
}
</style>
