import axios from 'axios';

import apiConfig from '@/configs/api'

const state = {
    user: {
        dateTimeServer: '',
        fullName: '',
        listSalon: [],
        userId: ''
    },
    creds: {
        AccessToken: '',
        IdToken: '',
        RefreshToken: ''
    }
}

const getters = {
    user: state => state.user,
    accessToken: state => state.creds.AccessToken
}

const actions = {
    async setCreds({ commit }, creds) {
        commit('setCreds', creds);
    },

    async setUser({ commit }) {
        const { data: user } = await axios.get(`${apiConfig.API_TIMELINE}/api/timeline/user`);
        commit('setUser', user);
    }
}

const mutations = {
    setUser(state, user) {
        state.user = Object.freeze(user);
    },
    setCreds(state, creds) {
        state.creds = Object.freeze(creds);
    },
}


export default {
    state,
    actions,
    mutations,
    getters
}